import { ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import * as _ from 'lodash'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../constants/success-settings'
import { getFieldType, isRadioGroup, isUploadButton, toMiliseconds } from '../viewer-utils'
import { getInputValue } from '../input-value'
import { fetchRetry } from '../../utils/fetch-utils'
import { FieldPreset } from '../../constants/field-types'

const SUBMIT_API_URL = '/_api/wix-form-builder-web/submit'

const getLabel = field => field.connectionConfig.crmLabel

const isSubscribeField = field => field.connectionConfig.fieldType === FieldPreset.GENERAL_SUBSCRIBE

const getCrmType = field => {
  if (isUploadButton(field)) {
    return 'attachment'
  }

  if (isSubscribeField(field)) {
    return 'subscribe'
  }

  return field.connectionConfig.crmType
}

const createField = (field, attachments) => ({
  label: getLabel(field),
  crmType: getCrmType(field),
  crmTag: field.connectionConfig.crmTag,
  customFieldId: field.connectionConfig.customFieldId,
  value: getInputValue(field, attachments),
  inputType: getFieldType(field),
})

const sendActivity = ($w, { attachments, fields, wixLocation, wixWindow, instance }) => {
  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '' } = form.connectionConfig
  const payload = {
    config: { emailId, secondEmailId },
    fields: _.map(fields, field => createField(field, attachments)),
    labels,
    attachments,
    formName,
    formId: form.uniqueId,
    viewMode: wixWindow.viewMode,
  }

  const method = 'post'
  const headers = { 'Content-Type': 'application/json' }
  const urlDirs = wixLocation.baseUrl.split('/')
  return fetchRetry(
    `${urlDirs.slice(0, urlDirs.length - 1).join('/')}${SUBMIT_API_URL}?instance=${instance}`,
    {
      method,
      headers,
      mode: 'cors',
      body: JSON.stringify(payload),
    }
  )
}

const showSuccessMessageIfExists = (
  message,
  { successMessage, secondsToResetForm, successActionType, successLinkValue },
  wixLocation,
  linksUtil
) => {
  if (successActionType !== SuccessActionTypes.LINK) {
    if (_.get(message, 'html', undefined) === undefined) {
      return Promise.resolve()
    }
    message.html = successMessage
    message.show()
    return new Promise(resolve =>
      setTimeout(
        () => resolve(message.hide()),
        toMiliseconds(secondsToResetForm || SecondsToResetDefaults.MIN)
      )
    )
  } else {
    setTimeout(() => wixLocation.to(linksUtil.toUrl(successLinkValue)), 100)
    return Promise.resolve()
  }
}

export class FormStrategy {
  constructor(protected submitArgs, private initInstance, private linksUtil) {}

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  validateFields(fields: any) {
    return _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0)
    ).every(field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }
      if ('valid' in field) {
        return field.valid
      }
      return true
    })
  }

  async execute({ attachments, fields }) {
    const { $w, wixLocation, wixWindow } = this.submitArgs
    return await sendActivity($w, {
      attachments,
      fields,
      wixLocation,
      wixWindow,
      instance: this.initInstance,
    })
  }

  async postSubmission() {
    const {
      $message,
      successMessage,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
    } = this.submitArgs
    await showSuccessMessageIfExists(
      $message,
      {
        successMessage,
        secondsToResetForm,
        successActionType,
        successLinkValue,
      },
      wixLocation,
      this.linksUtil
    )
  }
}
